import './styles.scss';

import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Toggle } from '@components/modules/toggle';
import { Role } from '@interfaces/role';
import { IUser } from '@interfaces/user';
import { useCreate, useTranslate, useUpdate } from '@refinedev/core';
import { Button, Col, Drawer, Form, Input, notification, Select, Space } from 'antd';
import { TENANT_RESOURCE_API } from 'pages/settings/user-settings';
import React, { useEffect, useState } from 'react';

interface IProps {
    visible: boolean;
    emitCloseCreateUser: () => void;
    emitInviteUser: () => void;
    roles: Role.IRole[];
    user: IUser.IUserDto | null;
    isSystemAdmin?: boolean;
}

enum InviteUserFormField {
    EMAIL = 'email',
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    ROLE_ID = 'roleId',
    SKIP_ONBOARDING = 'skipOnboarding',
}

export const CreateUserDrawer: React.FC<IProps> = ({
    visible,
    emitCloseCreateUser,
    emitInviteUser,
    roles,
    user,
    isSystemAdmin = false,
}: IProps) => {
    const translate = useTranslate();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [createUserForm] = Form.useForm();
    const { mutate: createUser, isLoading: creating } = useCreate();
    const { mutate: updateUser, isLoading: updating } = useUpdate();
    const [isSkipOnboarding, setIsSkipOnboarding] = useState(false);
    const RoleLabel = () => {
        return (
            <div className="flex justify-between w-full">
                <span>{translate('users.role')}:</span>
            </div>
        );
    };

    useEffect(() => {
        setIsEdit(!!user);
        if (user) {
            const existRole = roles?.find((role) => role?.id === user?.roleId);

            createUserForm.setFieldsValue({
                [InviteUserFormField.EMAIL]: user?.email,
                [InviteUserFormField.FIRST_NAME]: user?.firstName,
                [InviteUserFormField.LAST_NAME]: user?.lastName,
                [InviteUserFormField.ROLE_ID]: existRole ? existRole?.id : null,
                [InviteUserFormField.SKIP_ONBOARDING]: user?.isSkipOnboarding,
            });
        }
    }, [user, roles]);

    const onCreateUser = async () => {
        try {
            const isValid = await createUserForm.validateFields();

            if (isValid) {
                const formValue = createUserForm.getFieldsValue();
                const request: IUser.ICreateTenantUser = {
                    email: formValue[InviteUserFormField.EMAIL] ?? '',
                    firstName: formValue[InviteUserFormField.FIRST_NAME] ?? '',
                    lastName: formValue[InviteUserFormField.LAST_NAME] ?? '',
                    roleId: formValue[InviteUserFormField.ROLE_ID] ?? '',
                    skipOnboarding:
                        formValue[InviteUserFormField.SKIP_ONBOARDING] ?? isSkipOnboarding,
                };

                createUser(
                    {
                        resource: `${TENANT_RESOURCE_API}/staff`,
                        values: request,
                        successNotification: false,
                        errorNotification: false,
                    },
                    {
                        onSuccess: (data) => {
                            createUserForm.resetFields();
                            notification.success({
                                message: translate('users.create_user_successfully'),
                                type: 'success',
                            });
                            emitInviteUser();
                        },
                        onError: (error, variables, context) => {
                            console.log(error);
                            notification.error({
                                message: error?.message
                                    ? translate(`settings.${error?.response?.data?.error}`)
                                    : translate('users.create_user_failed'),
                                type: 'error',
                            });
                        },
                    },
                );
            }
        } catch (err) {
            console.error(err);
        }
    };

    const onUpdateUser = async () => {
        try {
            const isValid = await createUserForm.validateFields();

            if (isValid) {
                const formValue = createUserForm.getFieldsValue();
                const request: IUser.IUpdateTenantUser = {
                    firstName: formValue[InviteUserFormField.FIRST_NAME] ?? '',
                    lastName: formValue[InviteUserFormField.LAST_NAME] ?? '',
                    roleId: formValue[InviteUserFormField.ROLE_ID] ?? '',
                    skipOnboarding:
                        formValue[InviteUserFormField.SKIP_ONBOARDING] ?? isSkipOnboarding,
                };

                updateUser(
                    {
                        resource: `${TENANT_RESOURCE_API}/staff`,
                        id: user?.id as string,
                        values: request,
                        successNotification: false,
                        errorNotification: false,
                    },
                    {
                        onSuccess: (data) => {
                            notification.success({
                                message: translate('users.update_user_successfully'),
                                type: 'success',
                            });
                            createUserForm.resetFields();
                            emitInviteUser();
                        },
                        onError: (error, variables, context) => {
                            notification.error({
                                message: error?.message
                                    ? translate(`settings.${error?.message}`)
                                    : translate('users.update_user_failed'),
                                type: 'error',
                            });
                        },
                    },
                );
            }
        } catch (err) {
            console.error(err);
        }
    };

    const onSubmit = () => {
        if (isEdit) {
            onUpdateUser();
        } else {
            onCreateUser();
        }
    };

    return (
        <Drawer
            className="drawer-form-container invite-user-drawer-container"
            title={
                <span className="text-lg">
                    {isEdit ? translate('users.edit') : translate('users.create_user')}
                </span>
            }
            closable={true}
            onClose={emitCloseCreateUser}
            visible={visible}
            extra={
                <Space>
                    <Button
                        icon={false}
                        className="invite-user-button"
                        type="primary"
                        onClick={onSubmit}
                        loading={creating || updating}
                    >
                        <span>{isEdit ? translate('users.save') : translate('users.create')}</span>
                    </Button>
                </Space>
            }
        >
            <Form form={createUserForm} layout="vertical" className="invite-user-form">
                <Col span={24} className="invite-user-form-col">
                    <Form.Item
                        className="invite-user-form-item"
                        name={InviteUserFormField.EMAIL}
                        label={<span>{translate('users.email')}:</span>}
                        required
                        rules={[
                            {
                                required: true,
                                message: translate('users.email_required'),
                            },
                            { type: 'email', message: translate('users.email_invalid') },
                        ]}
                    >
                        {isEdit ? (
                            <span>{user?.email}</span>
                        ) : (
                            <Input
                                placeholder={translate('users.email_placeholder')}
                                onChange={(e) => {
                                    const _value: string = e?.target?.value;
                                    if (_value) {
                                        createUserForm.setFieldsValue({
                                            email: _value.trim().toLowerCase(),
                                        });
                                    }
                                }}
                            />
                        )}
                    </Form.Item>
                </Col>

                <Col sm={24} className="invite-user-form-col">
                    <Form.Item
                        className="invite-user-form-item"
                        name={InviteUserFormField.FIRST_NAME}
                        label={<span>{translate('users.first_name')}:</span>}
                        required
                        rules={[
                            {
                                required: true,
                                message: translate('users.first_name_required'),
                            },
                        ]}
                    >
                        <Input placeholder={translate('users.first_name_placeholder')} />
                    </Form.Item>
                </Col>

                <Col sm={24} className="invite-user-form-col">
                    <Form.Item
                        className="invite-user-form-item"
                        name={InviteUserFormField.LAST_NAME}
                        label={<span>{translate('users.last_name')}:</span>}
                        required
                        rules={[
                            {
                                required: true,
                                message: translate('users.last_name_required'),
                            },
                        ]}
                    >
                        <Input placeholder={translate('users.last_name_placeholder')} />
                    </Form.Item>
                </Col>

                {!isSystemAdmin && (
                    <Col span={24} className="invite-user-form-col">
                        <Form.Item
                            className="invite-user-form-item role"
                            name={InviteUserFormField.ROLE_ID}
                            label={<RoleLabel />}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: translate('users.role_required'),
                                },
                            ]}
                        >
                            <Select placeholder={translate('users.select_role_placeholder')}>
                                {roles?.length
                                    ? roles.map((role) => (
                                          <Select.Option key={role.id} value={role.id}>
                                              {role?.name}
                                          </Select.Option>
                                      ))
                                    : null}
                            </Select>
                        </Form.Item>
                    </Col>
                )}

                {!isEdit ? (
                    <>
                        <div className="toggle-password">
                            <Form.Item
                                className="invite-user-form-item mt-3"
                                name={InviteUserFormField.SKIP_ONBOARDING}
                            >
                                <Toggle
                                    onChange={(value) => {
                                        setIsSkipOnboarding(value);
                                    }}
                                />
                                <span className="pl-2 font-bold">
                                    {translate('users.skip_onboarding')}
                                </span>
                            </Form.Item>
                        </div>
                    </>
                ) : null}
            </Form>
        </Drawer>
    );
};
