import { BaseKey, useApiUrl, useCustomMutation, useOne } from '@refinedev/core';
import { yearToMonths } from '@utils/function';
import { Connector } from '@zodinet-pos/core-ui';
import { Button, Layout, notification, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { TIMEZONE_DEFAULT, VN_DATE_FORMAT_MINUTE } from '../../constants/index.constant';
import Response from '../../data-access/responses/response';
import { CurrencyType } from '../../enums/currency-type.enum';
import { SubscriptionOrderStatusEnum } from '../../enums/subscription-order-status.enum';
import { SubscriptionOrderTypeEnum } from '../../enums/subscription-order-type.enum';
import { getEnvConfig } from '../../getEnvConfig';
import { NApplication } from '../../interfaces/application';
import { NSubscriptionOrder } from '../../interfaces/subscription-order';
import { formatDate } from '../../utils/date';
import { formatPriceBaseOnCurrency } from '../../utils/resource';
import { NotificationPaymentModal } from '../extra-application-plan/notification-payment-modal';
import { QRModal } from '../extra-application-plan/qr-modal';
import s from './styles.module.scss';

export const SubscriptionOrderIndex = () => {
    const { id } = useParams();
    const { t, i18n } = useTranslation(['common']);
    const { mutate } = useCustomMutation<Response<NApplication.IPlanResponse>>();
    const apiUrl = useApiUrl();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [subscriptionOrder, setSubscriptionOrder] = useState<NSubscriptionOrder.IDetailInfo>();
    const [qrUrl, setQrUrl] = useState<string>();
    const [isOpenQrModal, setIsOpenQrModal] = useState<boolean>(false);
    const [title, setTitle] = useState<string>();

    const { data, refetch } = useOne<NSubscriptionOrder.IDetailInfo>({
        resource: 'v1/subscription-orders',
        id: id as BaseKey,
        queryOptions: {
            enabled: true,
        },
    });

    useEffect(() => {
        if (data?.data) setSubscriptionOrder(data.data);
    }, [data]);

    const handlePaymentSubscriptionOrder = () => {
        setIsLoading(true);
        if (!subscriptionOrder) return;

        const url = `${apiUrl}/v1/subscription-orders/payment/${subscriptionOrder.id}`;
        mutate(
            {
                url: url,
                method: 'put',
                values: { timezone: TIMEZONE_DEFAULT },
                errorNotification: false,
            },
            {
                onError: (error, _, __) => {
                    // An error happened!
                    const code = error?.response?.data?.error;

                    if (i18n.exists(`plan_form.errors.${code}`)) {
                        notification.error({
                            message: t(`plan_form.errors.${code}`, { ns: 'common' }),
                        });
                    } else {
                        notification.error({
                            message: code,
                        });
                    }

                    console.error(error);
                    setIsLoading(false);
                },
                onSuccess: (data) => {
                    const response = data.data?.data;

                    if (data.data?.isSuccess) {
                        setTitle(t(`plan_form.success.${subscriptionOrder.type}_success`));
                        setIsOpenQrModal(true);
                        setQrUrl(response.qrUrl);
                    } else {
                        notification.error({
                            message: t(`plan_form.errors.upgrade_table_error`),
                        });
                    }
                    setIsLoading(false);
                },
            },
        );
    };

    return (
        <>
            {subscriptionOrder ? (
                <Connector.default brokerUrl={getEnvConfig.MQTT_URL} options={{ keepalive: 60 }}>
                    <Layout className={`flex items-center justify-center ${s.subscriptionPage}`}>
                        <Spin spinning={isLoading}>
                            <section className={s.subscriptionContainer}>
                                <div className={s.headerContainer}>
                                    <div className={s.logoContainer}>
                                        <img
                                            src="/images/ffood_logo.png"
                                            alt="logo"
                                            className={s.logoImg}
                                        />
                                    </div>
                                    <p className={`mr-2 ${s.titleForm}`}>
                                        {t(`subscription_order.${subscriptionOrder?.type}_title`)}
                                    </p>
                                </div>
                                <div>
                                    <p className={s.subscriptionItem}>
                                        <span>{t('subscription_order.tenantName')}</span>
                                        <span>{subscriptionOrder.tenantName}</span>
                                    </p>
                                    <p className={s.subscriptionItem}>
                                        <span>{t('subscription_order.user_name')}</span>
                                        <span>{subscriptionOrder.fullName}</span>
                                    </p>
                                    <p className={s.subscriptionItem}>
                                        <span>{t('subscription_order.email')}</span>
                                        <span>{subscriptionOrder.email}</span>
                                    </p>
                                    <p className={s.subscriptionItem}>
                                        <span>{t('subscription_order.phone_number')}</span>
                                        <span>{subscriptionOrder.phoneNumber}</span>
                                    </p>
                                    {subscriptionOrder.type ===
                                    SubscriptionOrderTypeEnum.UPGRADE_LICENSE ? (
                                        <>
                                            {subscriptionOrder.currentExtraAppPlanName &&
                                                subscriptionOrder.status ===
                                                    SubscriptionOrderStatusEnum.WAITING_PAYMENT && (
                                                    <p className={s.subscriptionItem}>
                                                        <span>
                                                            {t('subscription_order.from_plan')}
                                                        </span>
                                                        <span>
                                                            {t(
                                                                `plan_form.plan_type.${subscriptionOrder.currentExtraAppPlanName.toLowerCase()}`,
                                                            ).toUpperCase()}
                                                        </span>
                                                    </p>
                                                )}

                                            <p className={s.subscriptionItem}>
                                                <span>{t('subscription_order.to_plan')}</span>
                                                <span>
                                                    {t(
                                                        `plan_form.plan_type.${subscriptionOrder.extraAppPlanName.toLowerCase()}`,
                                                    ).toUpperCase()}
                                                </span>
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <p className={s.subscriptionItem}>
                                                <span>
                                                    {t('subscription_order.service_package')}
                                                </span>
                                                <span>
                                                    {t(
                                                        `plan_form.plan_type.${subscriptionOrder.extraAppPlanName.toLowerCase()}`,
                                                    ).toUpperCase()}
                                                </span>
                                            </p>
                                            <p className={s.subscriptionItem}>
                                                <span>{t('subscription_order.quantity')}</span>
                                                <span>
                                                    {[
                                                        SubscriptionOrderTypeEnum.RENEW_LICENSE,
                                                        SubscriptionOrderTypeEnum.ADD_ON_PLAN,
                                                        SubscriptionOrderTypeEnum.ADD_ON_AND_RENEW_PLAN,
                                                    ].includes(subscriptionOrder.type)
                                                        ? `${yearToMonths(
                                                              subscriptionOrder.quantity,
                                                          )} ${t(
                                                              'subscription_order.frequency_type.month',
                                                          )}`
                                                        : `${subscriptionOrder.quantity} PC`}
                                                </span>
                                            </p>
                                        </>
                                    )}

                                    <p className={s.subscriptionItem}>
                                        <span>{t('subscription_order.created_at')}</span>
                                        <span>
                                            {formatDate(
                                                subscriptionOrder.createdAt,
                                                VN_DATE_FORMAT_MINUTE,
                                            )}
                                        </span>
                                    </p>
                                    {subscriptionOrder.type ===
                                        SubscriptionOrderTypeEnum.UPGRADE_LICENSE &&
                                    subscriptionOrder.status !==
                                        SubscriptionOrderStatusEnum.WAITING_PAYMENT ? (
                                        <></>
                                    ) : (
                                        <p className={s.subscriptionItem}>
                                            <span>{t('plan_form.fields.total_price')}</span>
                                            <span>
                                                {formatPriceBaseOnCurrency(
                                                    subscriptionOrder.totalPrice || 0,
                                                    CurrencyType.Vnd,
                                                )}
                                            </span>
                                        </p>
                                    )}
                                </div>
                                <p className={s.subscriptionItem}>
                                    <span>{t('subscription_order.status')}</span>
                                    <span>
                                        {t(
                                            `subscription_order.status_type.${subscriptionOrder.status}`,
                                        )}
                                    </span>
                                </p>
                                {subscriptionOrder.status ===
                                SubscriptionOrderStatusEnum.WAITING_PAYMENT ? (
                                    <Button
                                        type="primary"
                                        className={s.btn}
                                        onClick={handlePaymentSubscriptionOrder}
                                    >
                                        {t('plan_form.actions.payment_btn')} -{' '}
                                        {formatPriceBaseOnCurrency(
                                            subscriptionOrder.totalPrice,
                                            CurrencyType.Vnd,
                                        )}
                                    </Button>
                                ) : (
                                    <></>
                                )}
                            </section>
                            {qrUrl && (
                                <QRModal
                                    url={qrUrl}
                                    isOpen={isOpenQrModal}
                                    onClose={() => {
                                        setQrUrl(undefined);
                                        setIsOpenQrModal(false);
                                        setTitle(undefined);
                                    }}
                                    totalPrice={subscriptionOrder.totalPrice}
                                />
                            )}
                            {qrUrl && (
                                <NotificationPaymentModal
                                    objectId={subscriptionOrder.id}
                                    onClose={() => {
                                        refetch();
                                        setQrUrl(undefined);
                                    }}
                                    onCloseQrModal={() => {
                                        setIsOpenQrModal(false);
                                    }}
                                    title={title}
                                    id={subscriptionOrder.registrationId}
                                />
                            )}
                        </Spin>
                    </Layout>
                </Connector.default>
            ) : (
                <></>
            )}
        </>
    );
};
