import { PlusOutlined } from '@ant-design/icons';
import { mapAntdSorterToCrudSorting } from '@refinedev/antd';
import { FilterValue } from '@refinedev/antd/dist/definitions/table';
import { IResourceComponentsProps, useCustomMutation, useDelete, useTable } from '@refinedev/core';
import {
    Button,
    Drawer,
    Dropdown,
    Grid,
    Menu,
    notification,
    Table,
    TablePaginationConfig,
} from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PAGE_SIZE } from '../../constants/index.constant';
import { ItemVisibilityEnum } from '../../enums';
import { NCategory } from '../../interfaces/category';
import { Chip } from '../modules/chip';
import { CustomSearch } from '../modules/custom-search';
import { CustomPagination } from '../modules/pagination';
import { PopupDelete } from '../modules/popup-delete';
import { CategoryDetail } from './detail/detail';

type Prop = {
    name?: string;
};

export const Category: React.FC<IResourceComponentsProps<unknown, Prop>> = () => {
    const { t } = useTranslation(['marketplace']);
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const [categoryId, setCategoryId] = useState<string | null>();
    const breakpoint = Grid.useBreakpoint();
    const { mutate } = useDelete();

    const {
        setFilters,
        tableQueryResult,
        current,
        setCurrent,
        pageSize,
        setPageSize,
        sorters,
        setSorters,
    } = useTable<NCategory.ICategory>({
        resource: 'v1/bo/category',
        dataProviderName: 'marketplace',
        sorters: {
            initial: [
                {
                    field: 'name',
                    order: 'asc',
                },
            ],
        },
        pagination: {
            mode: 'server',
            pageSize: PAGE_SIZE,
        },
    });

    const remove = (id: string) => {
        mutate(
            {
                resource: `v1/bo/category`,
                dataProviderName: 'marketplace',
                id,
                successNotification: false,
                errorNotification: false,
            },
            {
                onSuccess: () => {
                    notification.success({
                        message: t('notifications.deleteSuccess', { ns: 'common' }),
                    });
                    tableQueryResult.refetch();
                    return;
                },

                onError: () => {
                    notification.error({
                        message: t('notifications.deleteError', { ns: 'common' }),
                    });
                },
            },
        );
    };

    const menu = (id: string, record: NCategory.ICategory) => (
        <Menu mode="vertical">
            <Menu.Item
                key="0"
                icon={
                    <img src={'/images/edit.svg'} alt="edit" className="cursor-pointer ml-1 mr-2" />
                }
                onClick={() => {
                    setCategoryId(id);
                    setShowDetail(true);
                }}
            >
                {t('actions.edit', { ns: 'common' })}
            </Menu.Item>

            <Menu.Item
                key="1"
                className="!p-0 delete-btn-item"
                icon={<PopupDelete id={id} remove={remove} />}
            ></Menu.Item>
        </Menu>
    );

    const onChangeTable = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        if (sorter && Object.keys(sorter).length > 0) {
            // Map Antd:Sorter -> refine:CrudSorting
            const crudSorting = mapAntdSorterToCrudSorting(sorter);
            setSorters(crudSorting);
        }
    };

    const onSearchCategory = (event: any) => {
        setCurrent(1);
        setFilters([
            {
                field: 'filter',
                operator: 'eq',
                value: event?.target?.value,
            },
        ]);
    };

    const renderStatus = (visibility: ItemVisibilityEnum) => {
        let label = '';
        let type = '';
        console.log(visibility);

        switch (visibility) {
            case ItemVisibilityEnum.HIDDEN:
                label = t('marketplace.category.hidden');
                type = 'disable';
                break;
            case ItemVisibilityEnum.UNAVAILABLE:
                label = t('marketplace.category.visibility.UNAVAILABLE');
                type = 'disable';
                break;
            default:
                label = t('marketplace.category.visible');
                type = 'success';
                break;
        }
        return <Chip {...{ label, type }} />;
    };

    return (
        <>
            <section>
                <div className="category-header md:flex items-center justify-between my-2">
                    <CustomSearch
                        placeholder={t('marketplace.category.search_category')}
                        className="category-search w-52"
                        onChange={(event: any) => {
                            onSearchCategory(event);
                        }}
                        allowClear={true}
                    />

                    <Button
                        type="primary"
                        className="mt-2 md:mt-0"
                        onClick={() => {
                            setShowDetail(true);
                            setCategoryId(null);
                        }}
                    >
                        <PlusOutlined />
                        {t('add_new', { ns: 'common' })}
                    </Button>
                </div>

                <div className="overflow-hidden">
                    <Table
                        dataSource={tableQueryResult?.data?.data ?? []}
                        pagination={false}
                        rowKey="id"
                        onChange={onChangeTable}
                        scroll={{ y: '100%', x: '800px' }}
                    >
                        <Table.Column
                            sorter
                            title={<>{t('marketplace.category.name')}</>}
                            dataIndex="name"
                            key="name"
                            render={(text, _) => <p className="table-tbody-text">{text}</p>}
                            width={250}
                        />

                        <Table.Column
                            sorter
                            title={<>{t('marketplace.category.status')}</>}
                            dataIndex="visibility"
                            key="visibility"
                            render={(text, _) => renderStatus(text)}
                            width={150}
                        />

                        <Table.Column
                            dataIndex="id"
                            key="action"
                            render={(id, record: NCategory.ICategory) => (
                                <div className="flex justify-end items-center">
                                    <Dropdown
                                        overlay={menu(record.id, record)}
                                        placement="bottomRight"
                                        arrow
                                        overlayClassName="contact-dropdown-container"
                                        trigger={['click']}
                                    >
                                        <img
                                            src="/images/icons/dots-vertical.svg"
                                            alt="more"
                                            className="cursor-pointer"
                                        />
                                    </Dropdown>
                                </div>
                            )}
                            width={100}
                        />
                    </Table>
                </div>

                <div className="pagination-container pt-3 pb-4 px-6">
                    <CustomPagination
                        pageSize={pageSize}
                        total={tableQueryResult?.data?.total}
                        current={current}
                        onChange={(value: number, size: number) => {
                            setCurrent(value);
                            setPageSize(size);
                        }}
                    />
                </div>
            </section>

            <Drawer
                title={
                    categoryId ? t('marketplace.category.update') : t('marketplace.category.add')
                }
                width={breakpoint.md ? '720px' : '100%'}
                visible={showDetail}
                onClose={() => {
                    setShowDetail(false);
                    setCategoryId(null);
                }}
            >
                {showDetail && (
                    <CategoryDetail
                        id={categoryId}
                        onChange={(value, reload) => {
                            setShowDetail(value);
                            if (reload) {
                                tableQueryResult.refetch();
                            }
                        }}
                    />
                )}
            </Drawer>
        </>
    );
};
