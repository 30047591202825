import './styles.scss';

import { FilterStatus } from '@components/modules/filter-status';
import { CustomPagination } from '@components/modules/pagination';
import { PAGE_SIZE } from '@constants/index.constant';
import { PERMISSIONS } from '@constants/permission';
import { IssueStatus } from '@enums/helpdesk.enum';
import { UserTypeEnum } from '@enums/user-type.enum';
import { IHelpdesk } from '@interfaces/helpdesk';
import { CreateButton, getDefaultSortOrder, mapAntdSorterToCrudSorting } from '@refinedev/antd';
import { FilterValue } from '@refinedev/antd/dist/definitions/table';
import {
    IResourceComponentsProps,
    useNavigation,
    usePermissions,
    useTable,
    useTranslate,
} from '@refinedev/core';
import { formatDate, getFullNameUser, getPublicMediaUrl } from '@utils/resource';
import { Avatar, Col, Image, Row, Table, TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';

interface IHelpdeskProps {
    name: string;
}

const LIST_HELPDESK_STATUS = [
    {
        label: 'Open',
        value: IssueStatus.OPEN,
    },
    {
        label: 'Closed',
        value: IssueStatus.CLOSED,
    },
];

export const HELPDESK_RESOURCE_API = 'v1/tickets';
export const HELPDESK_RESOURCE = 'wl_helpdesk';

export const HelpdeskIndex: React.FC<IResourceComponentsProps<unknown, IHelpdeskProps>> = () => {
    const {
        setFilters,
        tableQueryResult,
        setCurrent,
        current,
        setPageSize,
        pageSize,
        sorters,
        setSorters,
    } = useTable<IHelpdesk.Ticket>({
        resource: HELPDESK_RESOURCE_API,
        pagination: {
            mode: 'server',
            pageSize: PAGE_SIZE,
        },
        sorters: {
            initial: [
                {
                    field: 'lastestCreatedAt',
                    order: 'desc',
                },
            ],
        },
        filters: {
            initial: [
                {
                    field: 'toDate',
                    value: new Date(),
                    operator: 'eq',
                },
                {
                    field: 'status',
                    value: IssueStatus.OPEN,
                    operator: 'eq',
                },
            ],
        },
    });
    const translate = useTranslate();
    const { push } = useNavigation();
    const { data: userRights } = usePermissions<string[]>();

    const showItem = (item: IHelpdesk.Ticket): void => {
        const isViewDetail = userRights?.includes(PERMISSIONS.WL_HELPDESK_SHOW);
        if (!item?.id || !isViewDetail) return;
        push(`/wl/helpdesk/show/${item.id}?status=${item?.status}`);
    };

    const onChangeStatus = (status: string) => {
        setFilters([
            {
                field: 'status',
                value: status,
                operator: 'eq',
            },
        ]);
        setCurrent(1);
    };

    const onChangeTable = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        if (sorter && Object.keys(sorter).length > 0) {
            // Map Antd:Sorter -> refine:CrudSorting
            const crudSorting = mapAntdSorterToCrudSorting(sorter);
            setSorters(crudSorting);
        }
    };

    return (
        <>
            <section className="helpdesk-container w-full h-full">
                <Row className="w-full h-10 mb-6 header-action-row">
                    <Col span={12} className="header-action-col justify-start">
                        <FilterStatus
                            placeholder="Select status"
                            listStatus={LIST_HELPDESK_STATUS}
                            className="search-helpdesk-status"
                            onChangeStatus={(status: string) => onChangeStatus(status)}
                            defaultValue={IssueStatus.OPEN}
                        />
                    </Col>
                    <Col span={12} className="header-action-col justify-end">
                        <CreateButton icon={false} className="create-issue-button" type="primary">
                            <Image preview={false} src={'/images/icons/plus.svg'} />
                            <span className="ml-2">{translate('helpdesk.create_issue')}</span>
                        </CreateButton>
                    </Col>
                </Row>
                <Row className="helpdesk-table-container">
                    <Col span={24} className="flex flex-col">
                        <div className="helpdesk-total-container flex justify-between">
                            <div className="total-issues">
                                <span className="all-issues">
                                    {translate('helpdesk.all_issues')}
                                </span>
                                <span className="total-number-issues ml-2">
                                    <span>{tableQueryResult?.data?.total ?? 0}</span>
                                    <span className="ml-1">
                                        {(tableQueryResult?.data?.total ?? 0) <= 1
                                            ? translate('helpdesk.issue')
                                            : translate('helpdesk.issues')}
                                    </span>
                                </span>
                            </div>
                        </div>

                        <Table
                            dataSource={tableQueryResult.data?.data}
                            loading={tableQueryResult.isLoading}
                            onChange={onChangeTable}
                            pagination={false}
                            // pagination={{
                            //     current: current,
                            //     pageSize: pageSize,
                            //     total: tableQueryResult.data?.total,
                            //     onChange: (page, size) => {
                            //         setCurrent(page);
                            //         setPageSize(size);
                            //     },
                            // }}
                            rowKey="id"
                            className="helpdesk-table"
                            scroll={{ y: 'auto', x: 1024 }}
                            onRow={(item) => {
                                return {
                                    onClick: () => showItem(item),
                                };
                            }}
                        >
                            <Table.Column
                                title={translate('helpdesk.issue')}
                                key="issue"
                                render={(text, _, index) => (
                                    <div className="issue-cell">
                                        <div className="issue-cell-subject">{text?.subject}</div>
                                        <div className="issue-cell-message">
                                            {text?.lastestMessage}
                                        </div>
                                    </div>
                                )}
                            />

                            <Table.Column
                                title={translate('helpdesk.status')}
                                key="status"
                                width={100}
                                render={(item, _) => (
                                    <div className="status-cell">
                                        <div
                                            className={`status-cell-label text-center ${item?.status}`}
                                        >
                                            {item?.status
                                                ? translate(`helpdesk.status_enum.${item?.status}`)
                                                : ''}
                                        </div>
                                    </div>
                                )}
                            />

                            <Table.Column
                                title={translate('helpdesk.user')}
                                key="user"
                                width={200}
                                render={(item, _) => (
                                    <div className="customer-cell flex items-center">
                                        {item?.user?.avatarUrl ? (
                                            <div className="mr-2">
                                                <Image
                                                    className="customer-cell-avatar"
                                                    src={getPublicMediaUrl(item?.user?.avatarUrl)}
                                                    preview={false}
                                                />
                                            </div>
                                        ) : (
                                            <Avatar
                                                className="customer-cell-avatar mr-3"
                                                size="large"
                                            >
                                                {item?.user?.firstName?.charAt(0)}
                                            </Avatar>
                                        )}

                                        <div className="table-tbody-text">
                                            {`${item?.user?.firstName} ${item?.user?.lastName}`}
                                        </div>
                                    </div>
                                )}
                            />

                            <Table.Column
                                title={translate('helpdesk.priority')}
                                key="priority"
                                width={100}
                                render={(item, _) => (
                                    <div className="priority-cell">
                                        <div
                                            className={`priority-cell-label text-center ${item?.priority} flex items-center justify-center w-fit`}
                                        >
                                            <span className="dot"></span>
                                            <span className="ml-2">
                                                {item?.priority
                                                    ? translate(
                                                          `helpdesk.priority_enum.${item?.priority}`,
                                                      )
                                                    : ''}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            />

                            <Table.Column
                                title={translate('helpdesk.partner')}
                                key="partner"
                                width={250}
                                render={(item, _) => (
                                    <div className="partner-cell flex items-center">
                                        {item?.partner?.userType ===
                                        UserTypeEnum.AFFILIATE_PARTNER ? (
                                            <div className="mr-2">
                                                {item?.partner?.avatarUrl ? (
                                                    <Image
                                                        className="customer-cell-avatar mr-3"
                                                        src={getPublicMediaUrl(
                                                            item?.partner?.avatarUrl,
                                                        )}
                                                        preview={false}
                                                    />
                                                ) : item?.partner?.firstName ? (
                                                    <Avatar
                                                        style={{
                                                            verticalAlign: 'middle',
                                                        }}
                                                        size="large"
                                                    >
                                                        {item?.partner?.firstName?.charAt(0)}
                                                    </Avatar>
                                                ) : null}
                                            </div>
                                        ) : (
                                            <div className="mr-2">
                                                {item?.partner?.faviconUrl ? (
                                                    <Image
                                                        className="customer-cell-avatar mr-3"
                                                        src={getPublicMediaUrl(
                                                            item?.partner?.faviconUrl,
                                                        )}
                                                        preview={false}
                                                    />
                                                ) : item?.partner?.name ? (
                                                    <Avatar
                                                        style={{
                                                            verticalAlign: 'middle',
                                                        }}
                                                        size="large"
                                                    >
                                                        {item?.partner?.name?.charAt(0)}
                                                    </Avatar>
                                                ) : null}
                                            </div>
                                        )}

                                        <div className="table-tbody-text">
                                            {item?.partner?.userType ===
                                            UserTypeEnum.AFFILIATE_PARTNER
                                                ? getFullNameUser(item.partner)
                                                : item?.partner?.name}
                                        </div>
                                    </div>
                                )}
                            />

                            <Table.Column
                                sorter
                                defaultSortOrder={getDefaultSortOrder('createdAt', sorters)}
                                title={translate('helpdesk.created_at')}
                                key="createdAt"
                                dataIndex="createdAt"
                                width={150}
                                render={(text, _, index) => (
                                    <p className="table-tbody-text">{formatDate(text)}</p>
                                )}
                            />
                        </Table>
                        <div className="pagination-container pt-3 pb-4 px-6">
                            <CustomPagination
                                pageSize={pageSize}
                                total={tableQueryResult?.data?.total}
                                current={current}
                                onChange={(value: any, size: any) => {
                                    setCurrent(value);
                                    setPageSize(size);
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </section>
        </>
    );
};
