import './styles.scss';

import { AntDesignOutlined, PlusOutlined } from '@ant-design/icons';
import { CustomSearch } from '@components/modules/custom-search';
import { FilterStatus } from '@components/modules/filter-status';
import { CustomPagination } from '@components/modules/pagination';
import { PAGE_SIZE } from '@constants/index.constant';
import { PERMISSIONS } from '@constants/permission';
import { QuoteStatus } from '@enums/quote-status.enum';
import { IQuote } from '@interfaces/quote';
import {
    CreateButton,
    DeleteButton,
    EditButton,
    getDefaultSortOrder,
    mapAntdSorterToCrudSorting,
    ShowButton,
} from '@refinedev/antd';
import { FilterValue } from '@refinedev/antd/dist/definitions/table';
import {
    LogicalFilter,
    useApiUrl,
    useCustomMutation,
    useNotification,
    usePermissions,
    useTable,
} from '@refinedev/core';
import { convertPrice, formatDate, getPublicMediaUrl } from '@utils/resource';
import { Avatar, Dropdown, Menu, Table, TablePaginationConfig, Tooltip } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const QuoteIndex: React.FC = () => {
    const { t } = useTranslation(['quote']);
    const LIST_FILTER_STATUS = [
        {
            label: t(`quotes.status.${QuoteStatus.Approved}`),
            value: QuoteStatus.Approved.toString(),
        },
        {
            label: t(`quotes.status.${QuoteStatus.Pending}`),
            value: QuoteStatus.Pending.toString(),
        },
        {
            label: t(`quotes.status.${QuoteStatus.Rejected}`),
            value: QuoteStatus.Rejected.toString(),
        },
        {
            label: t(`quotes.status.${QuoteStatus.Paid}`),
            value: QuoteStatus.Paid.toString(),
        },

        {
            label: t(`quotes.status.${QuoteStatus.Void}`),
            value: QuoteStatus.Void.toString(),
        },
    ];

    const {
        setFilters,
        filters,
        setSorters,
        sorters,
        tableQueryResult,
        setCurrent,
        current,
        setPageSize,
        pageSize,
    } = useTable<IQuote.QuoteList>({
        resource: 'v1/quotes',
        sorters: {
            initial: [
                {
                    field: 'incrementId',
                    order: 'desc',
                },
            ],
        },
        pagination: {
            pageSize: PAGE_SIZE,
            current: 1,
        },
        syncWithLocation: true,
    });

    const [loading, setLoading] = useState<boolean>(false);

    const { mutate } = useCustomMutation<any>();
    const apiUrl = useApiUrl();
    const { data: permissionsData } = usePermissions<string[]>();
    const { open: openNotification } = useNotification();

    const menu = (id: string, record: IQuote.QuoteList) => (
        <Menu mode="vertical">
            {record?.status === QuoteStatus.Pending &&
                (permissionsData || []).indexOf(PERMISSIONS.WL_QUOTE_CREATE) >= 0 && (
                    <Menu.Item
                        key="0"
                        icon={
                            <img
                                src="/images/icons/send.svg"
                                alt="send"
                                className="cursor-pointer mr-3"
                            />
                        }
                        onClick={() => {
                            sendQuote(id);
                        }}
                    >
                        {t('actions.send')}
                    </Menu.Item>
                )}

            <Menu.Item
                key="1"
                className="p-0"
                icon={
                    <ShowButton
                        recordItemId={id}
                        style={{
                            margin: '0px',
                        }}
                    />
                }
            ></Menu.Item>
            {record?.status !== QuoteStatus.Paid && (
                <>
                    {(record?.status === QuoteStatus.Draft ||
                        record?.status === QuoteStatus.Pending) &&
                    (permissionsData || []).indexOf(PERMISSIONS.WL_QUOTE_EDIT) >= 0 ? (
                        <Menu.Item
                            key="2"
                            style={{ padding: 0 }}
                            icon={
                                <EditButton
                                    recordItemId={id}
                                    style={{
                                        margin: 0,
                                        padding: '5px 36px 5px 12px',
                                    }}
                                />
                            }
                        ></Menu.Item>
                    ) : null}
                    {(permissionsData || []).indexOf(PERMISSIONS.WL_QUOTE_DELETE) >= 0 &&
                        record?.status !== QuoteStatus.Void && (
                            <Menu.Item
                                key="3"
                                style={{ padding: 0 }}
                                icon={
                                    <DeleteButton
                                        successNotification={{
                                            message: 'Successfully deleted',
                                            type: 'success',
                                        }}
                                        resource="v1/quotes"
                                        recordItemId={id}
                                        style={{
                                            margin: 0,
                                            padding: '5px 16px 5px 12px',
                                        }}
                                        errorNotification={(err) => {
                                            const errorMsg = (err as { message: string })?.message;
                                            if (errorMsg === 'quote_was_paid') {
                                                return {
                                                    message: t('quotes.error.quote_was_paid'),
                                                    type: 'error',
                                                };
                                            }

                                            if (errorMsg === 'existed_invoice_has_been_paid') {
                                                return {
                                                    message: t(
                                                        'quotes.error.existed_invoice_has_been_paid',
                                                    ),
                                                    type: 'error',
                                                };
                                            }

                                            return {
                                                message: t('quotes.error.delete_quote_failed'),
                                                type: 'error',
                                            };
                                        }}
                                    />
                                }
                            ></Menu.Item>
                        )}
                </>
            )}
        </Menu>
    );

    const sendQuote = (id: string) => {
        setLoading(true);
        mutate(
            {
                url: `${apiUrl}/v1/quotes/${id}/send`,
                method: 'post',
                values: {},
            },
            {
                onError: (error, variables, context) => {
                    // An error happened!
                    console.error(error);
                    setLoading(false);
                    openNotification?.({
                        message: 'Fail sent',
                        type: 'error',
                    });
                },
                onSuccess: (data, variables, context) => {
                    const result = data?.data;
                    setLoading(false);
                    openNotification?.({
                        message: 'Successfully sent',
                        type: 'success',
                    });
                },
            },
        );
    };

    const onChangeFilter = (event: any) => {
        setFilters((prev) => {
            const newFilter = prev;
            const index = newFilter.findIndex((item) => (item as LogicalFilter).field === 'filter');
            if (index !== -1) {
                (newFilter[index] as LogicalFilter).value = event.target.value;
            } else {
                newFilter.push({
                    field: 'filter',
                    operator: 'eq',
                    value: event.target.value,
                });
            }
            return newFilter;
        });

        setCurrent(1);
    };

    const onChangeStatus = (status: string) => {
        setFilters((prev) => {
            const newFilter = prev;
            const index = newFilter.findIndex((item) => (item as LogicalFilter).field === 'status');
            if (index !== -1) {
                (newFilter[index] as LogicalFilter).value = status;
            } else {
                newFilter.push({
                    field: 'status',
                    operator: 'eq',
                    value: status,
                });
            }
            return newFilter;
        });

        setCurrent(1);
    };

    const onChangeTable = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
        if (sorter && Object.keys(sorter).length > 0) {
            // Map Antd:Sorter -> refine:CrudSorting
            const crudSorting = mapAntdSorterToCrudSorting(sorter);
            setSorters(crudSorting);
        }
    };

    return (
        <>
            <section className="item-list-container quote-page-wrapper">
                <div className="list-header">
                    <div className="filter-wrapper">
                        <CustomSearch
                            placeholder={t('quotes.fields.search.quote_placeholder')}
                            className={'search-item'}
                            onChange={(event: any) => onChangeFilter(event)}
                            defaultValue={filters?.find((f: any) => f.field === 'filter')?.value}
                            allowClear={true}
                        />
                        <FilterStatus
                            placeholder={t('quotes.fields.search.status_placeholder')}
                            listStatus={LIST_FILTER_STATUS}
                            className="search-status"
                            defaultValue={filters?.find((f: any) => f.field === 'status')?.value}
                            onChangeStatus={(status: string) => onChangeStatus(status)}
                        />
                    </div>

                    {(permissionsData || []).indexOf(PERMISSIONS.WL_QUOTE_CREATE) >= 0 && (
                        <CreateButton className="btn-add-new" type="primary" icon={null}>
                            <PlusOutlined />
                            {t('add_new', { ns: 'common' })}
                        </CreateButton>
                    )}
                </div>

                <div className="overflow-hidden">
                    <div className="list-content table-wrapper">
                        <Table
                            dataSource={tableQueryResult.data?.data}
                            loading={tableQueryResult.isFetching}
                            onChange={onChangeTable}
                            pagination={false}
                            rowKey="id"
                            className="quotes-table"
                            scroll={{ x: '900px', y: '100%' }}
                        >
                            <Table.Column
                                sorter
                                defaultSortOrder={getDefaultSortOrder('incrementId', sorters)}
                                title={<>{t('quotes.table.number')}</>}
                                dataIndex="incrementId"
                                key="incrementId"
                                width={80}
                                render={(text, _, index) => (
                                    <p className="table-tbody-text">{text}</p>
                                )}
                            />
                            <Table.Column
                                title={<>{t('quotes.table.billTo')}</>}
                                dataIndex="contactName"
                                key="contactName"
                                sorter
                                defaultSortOrder={getDefaultSortOrder('contactName', sorters)}
                                width={300}
                                render={(row, record: IQuote.QuoteList, index) => {
                                    const hasPermission = (permissionsData || []).includes(
                                        PERMISSIONS.WL_QUOTE_SHOW,
                                    );
                                    return (
                                        <div className="flex justify-between items-center ">
                                            <Tooltip
                                                title={
                                                    hasPermission ? (
                                                        <ShowButton
                                                            className="p-0 table-clickable-link"
                                                            type="link"
                                                            icon={false}
                                                            recordItemId={record.id}
                                                        >
                                                            {row}
                                                        </ShowButton>
                                                    ) : (
                                                        <>{row}</>
                                                    )
                                                }
                                            >
                                                <div className="flex justify-start items-center">
                                                    <Avatar
                                                        size={40}
                                                        src={
                                                            record.contactAvatarUrl
                                                                ? getPublicMediaUrl(
                                                                      record.contactAvatarUrl,
                                                                  )
                                                                : null
                                                        }
                                                        icon={<AntDesignOutlined />}
                                                    />
                                                    <p className="table-tbody-text ml-2 item-name pd-0">
                                                        {row}
                                                    </p>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    );
                                }}
                            />
                            <Table.Column
                                sorter
                                width={120}
                                defaultSortOrder={getDefaultSortOrder('status', sorters)}
                                title={<>{t('quotes.table.status')}</>}
                                dataIndex="status"
                                key="status"
                                render={(text, _, index) => (
                                    <p className={`table-tbody-text status-${text}`}>
                                        {t('quotes.status.' + text)}
                                    </p>
                                )}
                            />
                            <Table.Column
                                sorter
                                defaultSortOrder={getDefaultSortOrder('amount', sorters)}
                                title={<>{t('quotes.table.amount')}</>}
                                dataIndex="amount"
                                key="amount"
                                width={120}
                                render={(text, _, index) => (
                                    <p className="table-tbody-text">{convertPrice(text)}</p>
                                )}
                            />
                            <Table.Column
                                width={200}
                                title={<>{t('quotes.table.frequency')}</>}
                                dataIndex="frequencies"
                                key="frequencies"
                                render={(frequencies, _, index) => (
                                    <p className="table-tbody-text">
                                        {frequencies
                                            .map((f: string) => t('quotes.frequency.' + f))
                                            .join(', ')}
                                    </p>
                                )}
                            />
                            <Table.Column
                                width={200}
                                sorter
                                defaultSortOrder={getDefaultSortOrder('date', sorters)}
                                title={<>{t('quotes.table.expected_payment_date')}</>}
                                dataIndex="date"
                                key="date"
                                render={(text, _, index) => (
                                    <p className="table-tbody-text">{formatDate(text)}</p>
                                )}
                            />
                            <Table.Column
                                width={200}
                                sorter
                                defaultSortOrder={getDefaultSortOrder('dueDate', sorters)}
                                title={<>{t('quotes.table.dueDate')}</>}
                                dataIndex="dueDate"
                                key="dueDate"
                                render={(text, _, index) => (
                                    <p className="table-tbody-text">{formatDate(text)}</p>
                                )}
                            />
                            <Table.Column
                                dataIndex="id"
                                key="action"
                                width={100}
                                fixed="right"
                                render={(id, record: IQuote.QuoteList, index) => (
                                    <div
                                        className="flex justify-between items-center"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        <Dropdown
                                            overlay={menu(id, record)}
                                            placement="bottomRight"
                                            className="w-8 ml-auto"
                                            arrow
                                            overlayClassName="contact-dropdown-container"
                                            trigger={['click']}
                                        >
                                            <div className="">
                                                <img
                                                    src="/images/icons/dots-vertical.svg"
                                                    alt="more"
                                                    className="cursor-pointer"
                                                />
                                            </div>
                                        </Dropdown>
                                    </div>
                                )}
                            />
                        </Table>
                    </div>
                </div>
                <div className="pagination-container pt-3 pb-4 px-6">
                    <CustomPagination
                        pageSize={pageSize}
                        total={tableQueryResult?.data?.total}
                        current={current}
                        onChange={(value: number, size: number) => {
                            setCurrent(value);
                            setPageSize(size);
                        }}
                    />
                </div>
            </section>
        </>
    );
};
